// @flow

import * as React from "react";
import {css, StyleSheet} from "aphrodite";
import {useContext, useState} from "react";
import validator from "email-validator";
import {useIntl} from "gatsby-plugin-react-intl";
import {COUNTRY_OPTIONS} from "../constants";
import {MEDIUM_FONT_FAMILY} from "../styles/typography";
import {
  BLACK,
  GRAY,
  SEAFOAM_5,
  RED,
  TEXT_BLACK,
  TEXT_PLACEHOLDER,
  TEXT_WHITE,
  VERY_LIGHT_GREY,
  WHITE,
  TEAL_4,
} from "../styles/colors";
import {SIDE_MARGIN, SIDE_MARGIN_WIDE, BREAKPOINTS} from "../styles/sizes";

import Button from "./Button";
import Checkbox from "./Checkbox";
import GDPRContext from "./gdpr/GDPRContext";
import Layout from "../container/Layout";
import Recaptcha from "react-google-recaptcha";
import dropdownImg from "../assets/dropdown.svg";

const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY;
const RECAPTCHA_LANG_MAP = {
  en: "en",
  de: "de",
};

type formDataField = {
  value: string | boolean,
  isInvalid: boolean,
  required: boolean,
};

type formDataType = {[key: string]: formDataField};

const createField = (value, required = true) => ({
  value,
  isInvalid: false,
  required,
});

const formBaseState = {
  firstName: createField(""),
  lastName: createField(""),
  company: createField(""),
  email: createField(""),
  country: createField(""),
  organizationType: createField(""),
  serviceRequested: createField(""),
  termsAccepted: createField(false),
  GDPRAccepted: createField(false, false),
};

type Props = {
  formHandlerURL?: ?string,
  campaign?: ?string,
};

const DEFAULT_FORM_HANDLER =
  "https://tech.flexport.com/l/925323/2024-12-11/2yl5jn";

function ContactForm({
  formHandlerURL = DEFAULT_FORM_HANDLER,
  campaign,
}: Props): React.Node {
  const intl = useIntl();
  const {isEU} = useContext(GDPRContext);
  const [formData, setFormData]: [formDataType, Function] =
    useState(formBaseState);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);
  const [recaptchaToggle, setRecaptchaToggle] = useState(false);

  const recaptchaLang = RECAPTCHA_LANG_MAP[intl.locale] || "en";

  const organizationOptions = [
    "corporation",
    "EU_entity",
    "LLC",
    "LLP",
    "none",
    "partnership",
    "sole",
    "ngo",
  ].map(key => ({
    label: intl.formatMessage({id: `contact_form_organization_${key}`}),
    value: intl.formatMessage(
      {id: `contact_form_organization_${key}`},
      {locale: "en"}
    ),
  }));

  const serviceOptions = [
    "disaster_relief",
    "shipping",
    "calculate_emissions",
    "offset_emissions",
    "API",
    "reduce_emissions",
  ].map(key => ({
    label: intl.formatMessage({id: `contact_form_service_${key}`}),
    value: intl.formatMessage(
      {id: `contact_form_service_${key}`},
      {locale: "en"}
    ),
  }));

  const validateField = (field, value) => {
    if (!value) return true;
    if (field === "email") return !validator.validate(value);
    return false;
  };

  const handleChange = e => {
    const {name, value, type, checked} = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData(prev => ({
      ...prev,
      [name]: {
        ...prev[name],
        value: newValue,
        isInvalid: validateField(name, newValue),
      },
    }));
  };

  const handleRecaptchaChange = token => {
    setRecaptchaToken(token);
    setIsRecaptchaVerified(!!token);
    setRecaptchaToggle(!!token);
  };

  const handleSubmit = e => {
    e.preventDefault();

    const hasErrors = Object.keys(formData).filter(field => {
      const name = field;
      const newValue = formData[field].value;
      setFormData(prev => ({
        ...prev,
        [name]: {
          ...prev[name],
          value: newValue,
          isInvalid: validateField(name, newValue),
        },
      }));

      return (
        formData[field].required &&
        (formData[field].isInvalid || !formData[field].value)
      );
    });

    // Toggle recaptcha error state:
    setRecaptchaToggle(!isRecaptchaVerified);

    if (hasErrors.length === 0 && isRecaptchaVerified) {
      const form = e.currentTarget.form;
      const recaptchaInput = document.createElement("input");
      recaptchaInput.type = "hidden";
      recaptchaInput.name = "g-recaptcha-response";
      recaptchaInput.value = recaptchaToken;
      form.appendChild(recaptchaInput);
      form.submit();
    }
  };

  const renderField = (name, label, type = "text", options) => (
    <div className={css(styles.formField)}>
      <div className={css(styles.formLabel)}>{label}</div>
      {options ? (
        <div
          className={css(
            styles.selectOuter,
            formData[name].isInvalid && styles.invalid
          )}
        >
          <select
            name={name}
            value={formData[name].value}
            required={formData[name].required}
            onChange={handleChange}
            onBlur={handleChange}
            className={css(styles.selectInner)}
          >
            <option value="" disabled>
              {intl.formatMessage({id: `contact_form_${name}_select`})}
            </option>
            {options.map(({label, value}) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
      ) : (
        <input
          name={name}
          type={type}
          value={formData[name].value}
          required={formData[name].required}
          onChange={handleChange}
          onBlur={handleChange}
          placeholder={intl.formatMessage({
            id: `contact_form_${name}_placeholder`,
          })}
          className={css(
            styles.formInput,
            formData[name].isInvalid && styles.invalid
          )}
        />
      )}
    </div>
  );

  return (
    <Layout slug="contact">
      <div className={css(styles.container)}>
        <form
          className={css(styles.form)}
          action={formHandlerURL}
          method="post"
          onSubmit={handleSubmit}
        >
          <div className={css(styles.formHead)}>
            <h1 className={css(styles.title)}>
              {intl.formatMessage({id: "contact_title"})}
            </h1>
            <p className={css(styles.subTitle)}>
              {intl.formatMessage({id: "contact_sub_title"})}
            </p>
          </div>
          <div className={css(styles.formBody)}>
            {renderField(
              "firstName",
              intl.formatMessage({id: "contact_form_first_name"})
            )}
            {renderField(
              "lastName",
              intl.formatMessage({id: "contact_form_last_name"})
            )}
            {renderField(
              "company",
              intl.formatMessage({id: "contact_form_company"})
            )}
            {renderField(
              "email",
              intl.formatMessage({id: "contact_form_email"})
            )}
            {renderField(
              "country",
              intl.formatMessage({id: "contact_form_country"}),
              "select",
              COUNTRY_OPTIONS
            )}
            {renderField(
              "organizationType",
              intl.formatMessage({id: "contact_form_org_type"}),
              "select",
              organizationOptions
            )}
            {renderField(
              "serviceRequested",
              intl.formatMessage({id: "contact_form_service"}),
              "select",
              serviceOptions
            )}
            <div className={css(styles.formField)}>
              <div className={css(styles.tac)}>
                <Checkbox
                  name="termsAccepted"
                  active={formData.termsAccepted.value}
                  required={formData.termsAccepted.required}
                  classes={
                    formData.termsAccepted.isInvalid && styles.checkboxInvalid
                  }
                  onClick={handleChange}
                  label={intl.formatMessage({id: "contact_form_terms"})}
                />
              </div>
            </div>
            {isEU && (
              <div className={css(styles.formField)}>
                <div className={css(styles.tac)}>
                  <Checkbox
                    name="GDPRAccepted"
                    active={formData.GDPRAccepted.value}
                    required={formData.GDPRAccepted.required}
                    onClick={handleChange}
                    label={intl.formatMessage({id: "contact_form_gdpr"})}
                  />
                </div>
              </div>
            )}
            <div
              className={css(
                styles.formField,
                styles.recaptcha,
                recaptchaToggle ? styles.recaptchaError : ""
              )}
            >
              <Recaptcha
                sitekey={RECAPTCHA_KEY}
                onChange={handleRecaptchaChange}
                hl={recaptchaLang}
              />
            </div>
            {campaign && (
              <input
                name="campaign"
                value={campaign}
                type="hidden"
                className={css(styles.hidden)}
              />
            )}
            <div className={css(styles.formField, styles.submit)}>
              <Button
                text={intl.formatMessage({id: "contact_form_submit"})}
                onClick={handleSubmit}
                componentStyle="green"
              />
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    boxSizing: "border-box",
    flexDirection: "column",
    alignItems: "center",
    background: VERY_LIGHT_GREY,
    padding: 0,
    minHeight: "calc(100vh - 105px)",
    [BREAKPOINTS.DESKTOP]: {
      padding: `10vh ${SIDE_MARGIN}`,
    },
    [BREAKPOINTS.DESKTOP_LARGE]: {
      padding: `10vh ${SIDE_MARGIN_WIDE}`,
    },
  },
  form: {
    background: WHITE,
    borderRadius: "unset",
    boxShadow: "unset",

    [BREAKPOINTS.DESKTOP]: {
      borderRadius: "6px",
      boxShadow:
        "0 50px 100px -20px rgb(50 50 93 / 5%), 0 30px 60px -30px rgb(0 0 0 / 5%), 0 -18px 60px -10px rgb(0 0 0 / 1%)",
    },
  },
  formHead: {
    background: SEAFOAM_5,
    color: WHITE,
    padding: "25px",
    borderRadius: "unset",
    boxSizing: "border-box",
    [BREAKPOINTS.DESKTOP]: {
      borderRadius: "6px 6px 0px 0px",
    },
  },
  title: {
    fontSize: "28px",
    margin: "0px 0px",
  },
  subTitle: {
    margin: "5px 0px 0px",
    color: TEXT_WHITE,
  },
  formBody: {
    padding: "15px 25px 25px 25px",
    boxSizing: "border-box",
    [BREAKPOINTS.DESKTOP]: {
      padding: "35px 45px 25px 45px",
    },
  },
  formField: {
    padding: "10px 0px 5px 0px",
    display: "flex",
    flexDirection: "column",
    [BREAKPOINTS.DESKTOP]: {
      flexDirection: "row",
    },
  },
  formLabel: {
    fontFamily: [MEDIUM_FONT_FAMILY],
    display: "flex",
    alignItems: "center",
    padding: "0px 25px 5px 0px",
    fontSize: "16px",
    color: TEXT_BLACK,
    paddingTop: "4px",
    width: "unset",
    [BREAKPOINTS.DESKTOP]: {
      width: "200px",
    },
  },
  formInput: {
    width: "unset",
    boxSizing: "border-box",
    background: VERY_LIGHT_GREY,
    padding: "8px 15px",
    borderRadius: "4px",
    border: "none",
    fontSize: "16px",
    outline: "none",
    maxHeight: "45px",
    borderBottom: `3px solid ${WHITE}`,
    "::placeholder": {
      color: GRAY,
    },
    ":-webkit-autofill": {
      transition: "background-color 100000000s",
    },
    ":focus-visible": {
      outlineColor: `${TEAL_4}`,
      outlineOffset: "2px",
      outlineStyle: "solid",
      outlineWidth: "3px",
    },
    [BREAKPOINTS.DESKTOP]: {
      width: "500px",
    },
  },
  invalid: {
    borderBottom: `3px solid ${RED}`,
  },
  checkboxInvalid: {
    ":invalid": {
      border: `2px solid ${RED}`,
    },
  },
  submit: {
    paddingTop: "25px",
    justifyContent: "flex-start",
    [BREAKPOINTS.DESKTOP]: {
      marginLeft: "225px",
      width: "500px",
    },
  },
  hidden: {display: "none"},
  selectOuter: {
    display: "flex",
    position: "relative",
    alignItems: "center",
    background: VERY_LIGHT_GREY,
    padding: "8px 15px",
    maxHeight: "45px",
    borderRadius: "4px",
    outline: "none",
    borderBottom: `3px solid ${WHITE}`,
    width: "unset",
    "::after": {
      content: '""',
      position: "absolute",
      width: "9px",
      height: "5px",
      right: "20px",
      top: "50%",
      marginTop: "-2px",
      backgroundImage: `url(${dropdownImg})`,
      pointerEvents: "none",
    },
    [BREAKPOINTS.DESKTOP]: {
      width: "500px",
    },
  },
  selectInner: {
    borderStyle: "none",
    outline: "none",
    appearance: "none",
    color: BLACK,
    position: "relative",
    cursor: "pointer",
    fontSize: "16px",
    background: "transparent",
    maxWidth: "100%",
    paddingRight: "20px",
    width: "100%",
    ":required:invalid": {
      color: TEXT_PLACEHOLDER,
    },
    ":focus": {
      color: BLACK,
    },
    ":focus-visible": {
      outlineColor: `${TEAL_4}`,
      outlineOffset: "10px",
      outlineStyle: "solid",
      outlineWidth: "3px",
    },
    ":-webkit-autofill": {
      transition: "background-color 100000000s",
    },
  },
  tac: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    fontSize: "14px",
    width: "unset",
    margin: "10px 0px",
    [BREAKPOINTS.TABLET_PORTRAIT]: {
      maxWidth: "500px",
    },
    [BREAKPOINTS.DESKTOP]: {
      marginLeft: "200px",
    },
  },
  recaptcha: {
    display: "flex",
    marginTop: "10px",
    [BREAKPOINTS.DESKTOP]: {
      marginLeft: "225px",
      width: "500px",
    },
  },
  recaptchaError: {
    outline: "4px solid red",
    background: "rgb(255 0 0 / 40%)",
  },
});

export const contactPageGlobalStyles: mixed = {
  ".react-dropdown-select": {
    width: "unset",

    [BREAKPOINTS.DESKTOP]: {
      width: "500px",
    },
  },
  ".react-dropdown-select-input": {
    margin: "0px",
    "::placeholder": {
      color: GRAY,
      fontSize: "16px",
    },
  },
};

export default ContactForm;
