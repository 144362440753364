// @flow
import * as React from "react";
import {css, StyleSheet} from "aphrodite";
import {SEAFOAM_5, LIGHT_GREY, TEXT_BLACK, RED, TEAL_4} from "../styles/colors";

type Props = {|
  active: boolean,
  onClick: () => void,
  label?: string,
  name: string,
  required?: required,
  classes?: string,
|};

const Checkbox = (props: Props): React.Node => {
  const {active, onClick, label, name, required = false, classes} = props;

  return (
    <label htmlFor={name} className={css(styles.checkboxWrapper)}>
      <input
        id={name}
        name={name}
        className={css(styles.input, classes ? classes : "")}
        type="checkbox"
        checked={active}
        onChange={onClick}
        required={required}
      />
      {label && (
        <span
          className={`${css(styles.checkboxLabel)} dangerouslySetContentStyle`}
          dangerouslySetInnerHTML={{__html: label}}
        />
      )}
    </label>
  );
};

const styles = StyleSheet.create({
  checkboxWrapper: {
    display: "flex",
    alignItems: "center",
    ":last-child": {
      cursor: "pointer",
      color: LIGHT_GREY,
    },
    ":hover": {
      ":last-child": {
        color: SEAFOAM_5,
      },
    },
  },
  checkboxLabel: {
    color: TEXT_BLACK,
  },
  input: {
    marginRight: 15,
    cursor: "pointer",
    boxSizing: "border-box",
    border: `1px solid ${LIGHT_GREY}`,
    borderRadius: "4px",
    width: "20px",
    height: "20x",
    minWidth: "20px",
    minHeight: "20px",
    appearance: "none",
    outline: "none",

    /* check svg icon in latitude */
    ":checked": {
      background: `${SEAFOAM_5} url("data:image/svg+xml,%3Csvg width='12px' height='12px' viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath d='M61.964,16.52 L23.778,54.706 C23.3853277,55.0977362 22.7496723,55.0977362 22.357,54.706 L16.557,48.906 L2.294,34.641 C1.90226377,34.2483277 1.90226377,33.6126723 2.294,33.22 L7.979,27.536 C8.37167233,27.1442638 9.00732767,27.1442638 9.4,27.536 L22.948,41.084 L54.738,9.294 C55.1306723,8.90226377 55.7663277,8.90226377 56.159,9.294 L61.964,15.1 C62.3550716,15.4925567 62.3550716,16.1274433 61.964,16.52 Z' fill='%23fff' %3E%3C/path%3E%3C/svg%3E") no-repeat center`,
      borderColor: "transparent",
    },
    ":user-invalid": {
      border: `2px solid ${RED}`,
      transition: "border-color 0.2s ease-in-out",
    },
    ":focus-visible": {
      outlineColor: `${TEAL_4}`,
      outlineOffset: "2px",
      outlineStyle: "solid",
      outlineWidth: "3px",
    },
  },
});

export default Checkbox;
